'use client';

import React from 'react'
import { getContactMailData } from '@/lib/helpers/mail/MailDataHelper';

export default function ContactForm() {
    const [loading, setLoading] = React.useState(false);

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');

    const [success, setSuccess] = React.useState(false);
    const [errors, setErrors] = React.useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    }
    
    async function submit(e) {
        e.preventDefault();
        setLoading(true);
        
        return new Promise((resolve, reject) => {
            const { subject, html } = getContactMailData({
                name: name,
                email: email,
                phone: phone,
                message: message
            });

            const emailConfig = {
                to: 'gerrit@webbeukers.nl',
                bcc: [],
                subject: subject,
                html: html
            };

            fetch('/api/mailer', {
                method: 'POST',
                headers: {
                    'Custom-Header-Token': process.env.NEXT_APP_API_BEARER ?? '',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emailConfig)
            }).then(data => {
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
                setErrors({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
                
                setSuccess(true);
                setLoading(false);
                resolve(true);
            }).catch(error => {
                console.error(error);
                setLoading(false);
                reject(false);
            });
        });
    }

    return (
        <React.Fragment>
            {success ? (
                <p className='text-putty font-bold text-center'>Bedankt voor uw bericht! We nemen zo snel mogelijk contact met u op.</p>
            ) : (
                <React.Fragment>
                    <p className="featured-paragraph max-w-200 text-center mx-auto">Laat een bericht achter en onze professionals nemen zo snel mogelijk contact met je op!</p>
                    <form className="flex flex-col gap-y-small w-full max-w-100 mx-auto" onSubmit={ (e) => submit(e) }>
                        <label className='label' htmlFor="name">
                            <span>Naam<span className='text-putty'>*</span></span>
                            <input className='input' type="text" placeholder="Naam*" required name='name' id='name' autoComplete='name' onChange={handleChange} />
                            {errors.name && <span className='text-red-400'>{errors.name}</span>}
                        </label>

                        <label className='label' htmlFor="email">
                            <span>E-mail<span className='text-putty'>*</span></span>
                            <input className='input' type="email" placeholder="E-mail*" required name='email' id='email' autoComplete='email' onChange={handleChange} />
                            {errors.email && <span className='text-red-400'>{errors.email}</span>}
                        </label>

                        <label className='label' htmlFor="phone">
                            <span>Telefoon<span className='text-putty'>*</span></span>
                            <input className='input' type="tel" placeholder="Telefoon*" required name='phone' id='phone' autoComplete='tel' onChange={handleChange} />
                            {errors.phone && <span className='text-red-400'>{errors.phone}</span>}
                        </label>

                        <label className='label' htmlFor="message">
                            <span>Kort bericht<span className='text-putty'>*</span></span>
                            <textarea className='input' placeholder="Kort bericht*" required name='message' id='message' autoComplete='off' onChange={handleChange} />
                            {errors.message && <span className='text-red-400'>{errors.message}</span>}
                        </label>
                        
                        <p className="featured-paragraph text-left">Velden met een <span className='text-putty'>*</span> zijn verplicht</p>
                        {loading && <p className='text-putty text-left'>Het formulier wordt verstuurd, even geduld...</p>}
                        <button type='submit' role='button' className="button-putty w-fit ml-auto disabled:opacity-60 disabled:cursor-not-allowed" disabled={loading}>Versturen</button>
                    </form>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
