export const getContactMailData = (formData = {}) => {
    const { name = '', email = '', phone = '', message = '' } = formData;

    const subject = `New message from ${name}`;
    const html = `
        <div>
            <h1>New message from ${name}</h1>
            <p>${message}</p>
            <p>${phone}</p>
            <p>Reply to: ${email}</p>
        </div>
    `;

    return {
        subject,
        html
    };
}